<p-button (click)="showRouteDialog()" [pTooltip]="tooltipName" tooltipPosition="top" icon="pi pi-plus"></p-button>
<p-dialog header="Create Route" [(visible)]="formPopupVisible" [draggable]="false" (onHide)="resetValues()" [modal]="true" [closable]="true"
  [style]="{width: '50vw', height:'75vh', 'overflow':'hidden'}">
  <form class="form px-3" [formGroup]="routeForm">
    <div class="row py-2">
      <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="businessLocation">Business Location</label>
        <p-dropdown [ngClass]="'blom-select'" [style]="{'width':'100%'}" optionLabel="value" optionValue="id"
          (onChange)="OnBLSelection($event.value)" [options]="businessLocationsList" formControlName="businessLocation"
          [placeholder]="'Select Business Location'" [filter]="true" filterBy="value"></p-dropdown>
        <span *ngIf="formValue['businessLocation'].invalid && formValue['businessLocation'].touched"
          class="error-validation">
          Business Location is required.</span>
      </div>

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="routeName">Route Name</label>
        <input id="routeName" class="form-control" type="text" formControlName="routeName">
        <span *ngIf="formValue['routeName'].invalid && formValue['routeName'].touched" class="error-validation">Route
          Name is
          required.</span>
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="routeState">Route State</label>
        <input id="routeState" class="form-control" type="text" value="Sorting" readonly>
      </div>
    </div>

    <p-divider></p-divider>

    <div class="row py-2">
      <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="routetype">Route Type</label>
        <p-dropdown formControlName="routetype" 
        [options]="routeTypeOptions" 
        [style]="{'width':'100%'}"
        showClear="true"
          placeholder="Select Route Type">
          
        </p-dropdown>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="workshift">Workshift</label>
        <p-dropdown optionLabel="workshift_name"
        [filter]="true" optionValue="workshift_id" formControlName="workshift"
          [style]="{'width':'100%'}" 
          (onFilter)="onWorkShiftFilter($event)"
          showClear="true"
          (onClear)="workShiftName = ''"
          (onChange)="handleWorkshiftChange($event)"
        [options]="workshiftList" placeholder="Select a workshift Id">
          <ng-template  pTemplate="footer">
            <div *ngIf="showAddWorkShiftFlag" class="d-grid gap-2">
              <button (click)="handleAddWorkshift()" class="btn btn-primary">{{workshiftFilterText}} ( Create Workshift )</button>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <div class="row py-2">
      <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="team">Teams</label>
        <p-dropdown optionLabel="name"
        showClear="true"
        optionValue="cost_center_id" formControlName="team" [style]="{'width':'100%'}"
          [options]="teamList" placeholder="Select a team"  appendTo="body"></p-dropdown>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="vehicle">Vehicle No.</label>
        <p-dropdown optionLabel="reg_no" optionValue="id" formControlName="vehicle" [style]="{'width':'100%'}"
          [options]="vehicleRegNo" 
          [filter]="true"
          showClear="true"
          (onFilter)="onVechileFilter($event)"
          (onClear)="vehicle_reg_no = ''"
          (onChange)="handleVehicleChange($event)"
          placeholder="Select a vehicle number">
          <ng-template  pTemplate="footer">
            <div *ngIf="showAddVehicleFlag" class="d-grid gap-2">
              <button (click)="handleAddVehicle()" class="btn btn-primary">{{vehicleFilterText}} ( Create Vehicle )</button>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <p-divider align="left">
      <div class="inline-flex">
        <i class="pi pi-calendar mr-4"></i>
        <b> Time Window</b>
      </div>
    </p-divider>
    <div class="row py-2">
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="date">Date</label>
        <p-calendar [(ngModel)]="date" [style]="{'width':'100%'}" [readonlyInput]="true" [showIcon]="true"
          appendTo="body" inputId="date" formControlName="date" [minDate]="minimumDate"></p-calendar>
        <span *ngIf="formValue['date'].invalid && formValue['date'].touched" class="error-validation">Date is
          required.</span>
      </div>
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="begin">From</label>
        <p-calendar inputId="timeonly" [style]="{'width':'100%'}" [timeOnly]="true" formControlName="begin"
          (onSelect)="onTimeChange()" appendTo="body"></p-calendar>
        <span *ngIf="formValue['begin'].invalid && formValue['begin'].touched" class="error-validation">From is
          required.</span>
      </div>
      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label class="form-label" for="end">To</label>
        <div class="position-relative">
          <p-calendar inputId="timeonly" [style]="{'width':'100%'}" [timeOnly]="true" formControlName="end"
            (onSelect)="onTimeChange()" appendTo="body"></p-calendar>
          
        </div>
        <span *ngIf="formValue['end'].errors?.['required'] && formValue['end'].touched" class="error-validation">To is
          required.</span>
         <span *ngIf="!formValue['end'].errors?.['required'] && formValue['end'].errors?.['invalidEndTime'] && formValue['end'].touched" class="error-validation">Must be greater than begin time</span>
      </div>
    </div>
  </form>
  <span class="px-3 py-4"><b>**Newly created options in the Vehicle and Workshift drodpowns will be saved only after clicking the 'Save'</b></span>
  <p-footer>
    <button class="btn btn-primary me-1" (click)="save()">Save</button>
    <button class="btn btn-secondary" (click)="resetValues(); formPopupVisible = false; ">Cancel</button>
  </p-footer>
</p-dialog>