export const environment = {
    production: true,
    airapikey:'k6cvyhKgB4kS4ROWkNcNMdih1596qNUd',
    airApiUri:'https://air-test.pgm.postnord.com/',
    pnBaseLayerUrl : 'https://tiles-prod.pgm.postnord.com/styles/postnord-basemap/{z}/{x}/{y}.png',
    redirectUri: 'https://dp-dev.postnord.com',
    postLogoutRedirectUri: 'https://dp-dev.postnord.com',
    plannedurl : 'https://dp-dev.postnord.com/dp/tasks?routeid=f5e63d52-2ea2-3164-80c2-fc990c1ff503&daysoffset=0&delta=false',
    pbmUrl: "https://pbm.npd-dev.postnord.com", 
    businessLocationurl : 'https://dp-dev.postnord.com/v4/businesslocations',
    routebundle:'https://dp-dev.postnord.com/v4/routebundles',
    baseUrl: 'https://dp-dev.postnord.com/',
    ProductionBookingUrl: 'https://pbm.npd-dev.postnord.com/#/',
    wspUrl : 'https://wsp.npd-dev.postnord.com/#/',
    ndmwebUrl :'https://ndm-dev.postnord.com/#/',
    adminUrl:'https://admin.npd-dev.postnord.com/#/',
    pathDP: 'dp/',
    pathV4: 'v4/',
    pathV1 : 'v1/',
    pathAddress : 'address',
    appInsights: {
        instrumentationKey: 'instrumentationKey=71242b6f-b557-4691-a627-ded0b7394977'
    },
    msalConfig: {
        auth: {
            clientId: 'aa667e16-6f9b-4a18-9131-1bc83de1faf4',
            authority: 'https://login.microsoftonline.com/91f09566-a850-4fae-bbe1-29ad3804a2f6',
        }
    },
    apiConfig: {
        scopes: ['openid', 'profile'],
        uri: 'https://graph.microsoft.com/v1.0/me',
        apirooturi: "https://dp-dev.postnord.com/dp/",
        apirootV4Uri: "https://dp-dev.postnord.com/v4/",
        apirootAddressUri: "https://dp-dev.postnord.com/address/",
    }
}

