<header>
    <nav class="navbar navbar-expand-sm">
        <ul class="navbar-nav hamburger">
            <li class="nav-item" >
                <div role="link" class="icon" (click)="toggleAppOptions()"></div>
            </li>
        </ul>
        <ul class="navbar-nav justify-content-between main-header">
            <li class="nav-item active align-items-center">
                <a class="navbar-brand d-inline-block pointer-cursor" href="#/dashboard">
                    <img src="./assets/image/postnord.svg" alt="logo" class="img-width">
                </a>
                <span *ngIf="desktopView === true" class="app-name pointer-cursor ">Distribution Planner</span>
            </li>
            
            <li class="nav-item d-flex align-items-center desktop-view" *ngIf="routePage === navigation.Dashboard 
            || routePage === navigation.Gantt || routePage === navigation.Map || routePage === navigation.MainKPI">
                <span><app-create-route></app-create-route></span>
                <span class="label" *ngIf="routePage === navigation.Dashboard">
                    <a role="link" (click)="toggleExpandView()" [pTooltip]="tooltipName" tooltipPosition="top">
                        <i [class]="icon"></i>
                    </a>
                </span>
                <div *ngFor="let item of keyMatrix" class="chip-label">
                    <span class="label"  pTooltip="{{item.task}}" tooltipPosition="top">
                        <i [class]="item.icon"></i>
                        {{ item.value }} 
                    </span><span class="label">|</span>
                </div>
                <span class="label">{{blName}}</span><span class="label">|</span>
                <span class="label">{{selectedDate}}</span>
                <app-filter></app-filter>
                <span class="label pointer-cursor" (click)="onLogout()"  ><i class="pi pi-power-off"></i> Logout </span>
            </li>
        </ul>
        <ul class="navbar-nav w-100 mobile-view">
            <li class="nav-item d-flex">
                <button pButton label="Logout" icon="pi pi-power-off" (click)="onLogout()"></button>
            </li>
        </ul>
    </nav>
</header>