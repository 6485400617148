import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionOption } from '../../entity/utility-interfaces';

@Component({
  selector: 'app-single-select-dropdown',
  templateUrl: './single-select-dropdown.component.html'
})
export class SingleSelectDropdownComponent {

  @Input() data: SelectionOption[] = [];
  @Output() selectionChange = new EventEmitter();
  @Input() placeholder: string | undefined;
  @Input() className: string | undefined;
  @Input() width: string | undefined;
  @Input() isDisabled?: any;
  selectedValue: any
  /**
   * Setter for the selection option
   */
  @Input() set selectedOption(value: any) {
    this.selectedValue = value??undefined;
  }
  constructor() {
  }

  onClickSelectionChange() {
    this.selectionChange.next(this.selectedValue.id);
  }

}
