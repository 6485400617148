<p-dropdown
  [ngClass]="className"
  [disabled]="isDisabled"
  [style]="{ width: width + '%' }"
  [options]="data"
  [(ngModel)]="selectedValue"
  optionLabel="value"
  filterBy="value"
  [filter]="true"
  [placeholder]="placeholder"
  name="selectedValue"
  appendTo="body"
  (onChange)="onClickSelectionChange()"
  [resetFilterOnHide]="true"
>
  <ng-template let-option pTemplate="item">
    <div class="flex align-items-center gap-2">
      <div>{{ option.value }}</div>
    </div>
  </ng-template>
</p-dropdown>