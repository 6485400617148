import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, type: string): unknown {
    if (value) {

      let dateObj: Date;
      // Check if value is an epoch timestamp (number or string representation of a number)
      if (typeof value === 'string' && /^\d+$/.test(value)) {
        dateObj = new Date(+value); // Convert the epoch to a Date object
      } else {
        dateObj = new Date(value);
      }
      // Convert the date to the desired time zone (Stockholm)
      const convertToGMT = new Date(new Date(dateObj).toLocaleString('sv-SE', {
        timeZone: 'Europe/Stockholm',
      })); 

      const dateFormat = convertToGMT;
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = months[dateFormat.getMonth()];
      const day = String(dateFormat.getDate()).padStart(2, '0');
      const year = dateFormat.getFullYear();
    
      // Extracting time components
      const hours = String(dateFormat.getHours()).padStart(2, '0');
      const minutes = String(dateFormat.getMinutes()).padStart(2, '0');
      const seconds = String(dateFormat.getSeconds()).padStart(2, '0');
    
      // Format the date and time
      let formattedDateTime = '';
      switch (type) {
        case DateFormatType.DATE:
          formattedDateTime = `${month}/${day}/${year}`;
          break;
        case DateFormatType.TIME:
          formattedDateTime = ` ${hours}:${minutes}`;
          break;
        case DateFormatType.DATETIME:
          const monthNo = dateFormat.getMonth();
          formattedDateTime = `${monthNo}/${day}/${year} ${hours}:${minutes}`;
          break;
          case DateFormatType.FULLDATETIME:
          formattedDateTime = `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
          break;
        default:
          break;
      }
      return formattedDateTime;

    }
    return null;
  }

}


export enum DateFormatType {
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  FULLDATETIME = 'fullDateTime'
}
