import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  @Input() hideAppOptions: boolean = true;
  @Output() appOptionsStatus = new EventEmitter();

  menuOptions: any[] = [];
  activeOption:any = null;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.menuOptions = [
      {
        name:'Production Booking Management',
        icon:'assets/image/production-booking.svg',
        link: environment.ProductionBookingUrl
      },
      {
        name:'Workshift Manager',
        icon:'assets/image/workship_icon.svg',
        link:environment.wspUrl
      },
      {
      name:'Distribution Planner',
      icon:'assets/image/pn_planner_icon (1).svg',
      route :'dashboard',
      subOption:[
      {
        name: 'MainKPI',
        icon: 'pi pi-book',
        route :'dashboard/mainKpi'
      },
      {
        name: 'Gantt',
        icon: 'pi pi-chart-bar',
        route :'gantt'
      },
      {
        name: 'Map',
        icon: 'pi pi-map-marker',
        route :'map'
      },
      {
        name: 'Lookup',
        icon: 'pi pi-search',
        route: 'lookup'
        
      },
      {
        name: 'Settings',
        icon: 'pi pi-cog',
        route :'settings'
      }],
      },
    {
      name:'NDM Web',
      icon:'assets/image/ndm_web_icon.svg' ,
      link :environment.ndmwebUrl

    },
    {
      name : 'Mystaff',
      icon : 'assets/image/mystaff.svg',
      link : 'https://mystaff.microsoft.com/'
    },
    {
      name:'Admin',
      icon:'assets/image/admin.svg',
      link:environment.adminUrl
    }
    ];
    this.activeOption = this.menuOptions[2]
  }

  navigateTo(option: any) {
    if(option.link){
      window.open(option.link, '_blank')
    }else if (option.route){
    this.router.navigate([`${option.route}`])
    this.hideAppOptions = true;
    this.appOptionsStatus.emit(this.hideAppOptions);
  }
}

  handleMenuClick(option:any){
    this.navigateTo(option)
  }

  expandOption(option:any) {
    if(this.activeOption === option){
      this.activeOption = null;
    }else {
      this.activeOption=option;
    }
  }
}
